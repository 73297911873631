<template>
  <div>
    <div class="search-container">
      <input type="text" placeholder="Search..." class="search-plan" v-model="searchQuery">
      <button type="submit" class="search-btn"><i class="fa fa-search"></i></button>
    </div>
    <div class="planselector-card">
      <b-row>
        <b-col col="12" sm="12" lg="4" md="6" xl="4">
          <div>
            <label id="website" for="website">Website<span style="color: red">*</span>
            </label>
            <div  class="drop-down-container">
              <select v-model="selectedOption" class="price-option" @change="getConfigurations('webSearch')">
                <option value="" disabled>Select an option</option>
                <option :value="webSite"
                        v-for="(webSite, index) in websites"
                        :key="index">{{webSite}}</option>
              </select>
            </div>
          </div>
        </b-col>
        <b-col  col="12" sm="12" lg="4" md="6" xl="4">
          <div v-if="selectedOption">
            <label id="website" for="website">Plan</label>
            <div class="drop-down-container">
              <select v-model="filterPlanType" class="price-option" @change="getConfigurations('filterPlanType')">
                <option value="" disabled>Select Plan Type</option>
                <option :value="plan.value"
                        v-for="(plan, index) in filterPlanCategory"
                        :key="index">{{plan.type}}</option>
              </select>
            </div>
          </div>
        </b-col>
        <b-col  col="12" sm="12" lg="4" md="6" xl="4">
          <div v-if="selectedOption">
            <label id="website" for="website">Category</label>
            <div class="drop-down-container">
              <select v-model="planCategoryType" class="price-option text-capitalize" @change="getConfigurations('filterPlanType')">
                <option value="" disabled>Select Plan Category Type</option>
                <option :value="plan"
                        v-for="(plan, index) in planCategoryList" class="text-capitalize"
                        :key="index"><span class="text-capitalize"> {{plan == 'term_life' ? removeUnderscores(plan) : plan}}</span></option>
              </select>
            </div>
          </div>
        </b-col>
        <b-col  col="12" sm="12" lg="12" md="12" xl="12">
          <div v-if="selectedOption">
            <div class="d-flex justify-content-between">
              <div class="remove-container" >
                <button class="remove-btn" @click="removePlan()">Remove all assigned Plan</button>
              </div>
              <div class="clear-search-container">
                <button class="clear-btn" @click="getConfigurations('clearFilter')">Clear Filter</button>
              </div>
            </div>
            </div>
            </b-col>
      </b-row>
     
      <!-- plan card  -->
      <div v-if="showPlanList">
        <div class="plan-wrapper mt-4">
          <div class="row mt-1" v-for="(planList, label) in filteredPlanListDetails" :key="label">
            <template v-if="filteredPlanListDetails[label].length > 0">
              <h2>{{ getLabelName(label) }} Plan</h2>
              <div
                  class="col-sm-12 col-md-6 col-lg-4 col-xxl-3  mb-4 align-items-stretch"
                  v-for="(plan, label) in planList" :key="label"
              >
                <div
                    class="plan-wrap"
                    :class="{ 'toggle-switch_card': plan.plan_configured == 1, 'card-checked': plan.is_featured == 1 }"
                >
                  <!-- <div class="w-100 m-0 "> -->
                  <div class="plan-card" >
                    <div class="d-flex justify-content-between">
                      <!-- <img :src="card.image" class="plan-logo" alt="Card Image">  -->
                      <img
                          :src="plan.plan_logo"
                          alt="Card Image"
                          class="plan-logo img-fluid"
                      />

                      <div class="toggle-switch"  @click="toggleSwitch(plan)" v-if="plan.can_configure == 1"
                           id="t-switch" :class="{ active: plan.plan_configured == 1}">
                        <div class="slider"></div>
                      </div>
                    </div>
                    <h6 class="mt-3 mb-2 plan-name">
                      <b>{{ plan.plan_name }}</b>
                    </h6>
                    <!--                    <div class=" assign-date">Assigned: 05/31/2023</div>-->
                    <div
                        class="check-content"
                    >
                      <div class="d-flex align-items-center">
                        <div  v-if="checkPlanConfig(plan)">
                          <label class="label-check">
                            <input type="checkbox" class="label_checkbox checkbox-plan" @click='checkConfiguration(plan.config_id, plan.is_featured)' disableToggle v-model="plan.is_featured" >
                          </label>
                          <div class="display-home">Display on Home Page</div>
                        </div>
                      </div>
                      <div>
<!--                        <button
                            v-b-tooltip.hover
                            title="view Price"
                            class="view-price_list"
                            :class="{ 'switch-on': switchOn }"
                            @click="openModal(label, plan); toggleSwitch();"
                        >
                          $
                        </button>-->
                      </div>
                    </div>
                    <div v-html="plan.plan_short_text" class="mt-2"></div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="price-popup">
        <b-modal
            id="planPrice"
            ref="planPrice"
            centered
            size="lg"
            hide-footer
        >
          <template #modal-header>
            <div class="price-header d-flex justify-content-center">
              <h4>Plan Price</h4>
              <b-button variant="btn btn-close-modal" @click="closeModal">
                <i class="fas fa-times"></i>
              </b-button>
            </div>
          </template>
          <div>
            <div class="price-form">
              <div>
                <div>
                  <div class="plan-card-wrap">
                    <div v-if="tierAgeInfo.plan_pricing_type == 'tier'">
                      <div v-for="(planPrice, index) in tierAgeInfo.plan_price" :key="index">
                        <div class="mb-2 p-2 ps-3 pe-3" v-if="planPrice.tier == 'IO'">
                          <div class="category-plan d-flex align-items-center justify-content-between">
                            <div>
                              <h6 class="mt-2">Member Only</h6>
                            </div>
                            <h5>${{planPrice.price_male_nons}}</h5>
                          </div>
                        </div>
                        <div class="mb-2 p-2 ps-3 pe-3" v-if="planPrice.tier == 'IS'">
                          <div
                              class="category-plan d-flex align-items-center justify-content-between"
                          >
                            <div>
                              <h6 class="mt-2">Member + Spouse</h6>
                            </div>
                            <h5>${{planPrice.price_male_nons}}</h5>
                          </div>
                        </div>
                        <div class="mb-2 p-2 ps-3 pe-3" v-if="planPrice.tier == 'IC'">
                          <div
                              class="category-plan d-flex align-items-center justify-content-between"
                          >
                            <div>
                              <h6 class="mt-2">Member + Children</h6>
                            </div>
                            <h5>${{planPrice.price_male_nons}}</h5>
                          </div>
                        </div>
                        <div class="mb-2 p-2 ps-3 pe-3" v-if="planPrice.tier == 'IF'">
                          <div
                              class="category-plan d-flex align-items-center justify-content-between"
                          >
                            <div>
                              <h6 class="mt-2">Family</h6>
                            </div>
                            <h5>${{planPrice.price_male_nons}}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="tierAgeInfo.plan_pricing_type == 'age'">
                      <div v-for="(planPrice, index) in tierAgeInfo.plan_price" :key="index">
                        <div class="mb-2 p-2 ps-3 pe-3">
                          <div
                              class="category-plan d-flex align-items-center justify-content-between"
                          >
                            <div>
                              <h6 class="mt-2">Age in between {{planPrice.age1}} and {{planPrice.age2}} for {{planPrice.tier}}</h6>
                            </div>
                            <h5>${{planPrice.price_male_nons}}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
      <div class="text-center" v-if="checkEmptyObj(filteredPlanListDetails) && selectedOption">
        No result found
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import axios from "axios";
import { mapGetters } from "vuex";
import {baseApiUrl} from "../../../resource/EndPoints/endpoints";
// import ToggleElement from '../../../components/ui/ToggleElement.vue';
export default {
  name: "HomepagePlanSelect",
  props: {
    card: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      default: () => [],
    },

  },
  data: () => {
    return {
      activePlan: null,
      isActive: false,
      isChecked: false,
      selectedOption: "",
      showDropdown: false,
      form: {
        website: null,
        plan_id: [],
        type: "agent",
      },
      isAllPlan: false,
      allPlansCount: 0,
      isCreate: false,
      repId: null,
      websites: [],
      filterPlanCategory: [
        {
          type: 'Configured Plan',
          value: 1
        },
        {
          type: 'Unconfigured Plan',
          value: 0
        },
        {
          type: 'All Plan',
          value: 'all'
        }],
      filterPlanType: '',
      activePlans: [],
      planListDetails: [],
      showPlanList: false,
      currentIndex: null,
      tierAgeInfo: [],
      selectedPlans: [],
      searchQuery: '',
      planCategoryList: [],
      planCategoryType: ''
    };
  },
  computed: {
    ...mapGetters({ rep: "rep" }),
    showPlanList() {
      return Object.keys(this.planListDetails).length > 0;
    },
    filteredPlanListDetails() {
      const filteredDetails = {};
      for (const category in this.planListDetails) {
        if (Object.prototype.hasOwnProperty.call(this.planListDetails, category)) {
          const plans = this.planListDetails[category];
          const filteredPlans = plans.filter(plan => plan.plan_name.toLowerCase().includes(this.searchQuery.toLowerCase()));
          filteredDetails[category] = filteredPlans;
        }
      }
      return filteredDetails;
    }
  },
  methods: {
 /*   openModal(index, plan) {
      this.$bvModal.show("planPrice");
      this.tierAgeInfo = []
      this.currentIndex = index;
      this.tierAgeInfo = plan;
    },*/
    checkEmptyObj(obj) {
      if (Object.keys(obj).length === 0) {
        return true;
      }

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && Array.isArray(obj[key]) && obj[key].length > 0) {
          return false;
        }
      }
      return true;
    },
    checkPlanConfig (plan) {
      // check for restircted site to display on home page
      if (this.selectedOption === 'premierenroll.com' || this.selectedOption === 'eliteenroll.com' || this.selectedOption === 'qa-elite.purenroll.com' || this.selectedOption === 'qa-premier.purenroll.com') {
        return false;
      }
      // check for plan configured or not
      if (plan.config_id && plan.can_configure == 1) {
        return true
      }
    },
    toggleSwitch(plan) {
      if (plan.plan_configured === 1) {
        plan.plan_configured = 0;
        const index = this.selectedPlans.indexOf(plan.plan_id);
        if (index > -1) {
          this.selectedPlans.splice(index, 1);
        }
        this.deletePlan(plan.config_id, plan)
      } else {
        const index = this.selectedPlans.indexOf(plan.plan_id);
        if (index > -1) {
          this.selectedPlans.splice(index, 1);
          this.deletePlan(plan.config_id, plan)
        } else {
          this.$confirm({
            title: "Assign Rep Plan",
            message: `Do you want to assign this plan?`,
            button: {
              no: "No",
              yes: "Yes",
            },
            callback: (confirm) => {
              if (confirm) {
                this.selectedPlans.push(plan.plan_id);
                this.addPlan(plan);
              }
            },
          });
        }
      }
    },
    isSelected(plan) {
      return this.selectedPlans.includes(plan);
    },
    reset() {
      this.form = {
        website: null,
        plan_id: [],
        type: "agent",
      };
      this.$refs.planObserver.reset();
    },
    addPlan(plan) {
      const url = "v2/add-group-agent-plan";
      let loader = this.$loading.show({
        container: this.$refs.addPlanContainer,
      });
      let params = {}
      params['agent_id'] = this.repId
      params['plan_id'] = [plan.plan_id]
      params['website'] = this.selectedOption
      params['type'] = 'agent'
      Api.post(url, params)
          .then((res) => {
            let response = res.data.message;
            this.$notify(response);
            this.getConfigurations('filterPlanType');
            this.reset();
          })
          .catch((err) => {
            this.$notify(err.response.data.message, "error");
          })
          .finally(() => {
            setTimeout(() => {
              loader.hide();
            }, 2000);
          });
    },
    deletePlan(planConfigId, plan) {
      const url = `v2/delete-group-agent-website-plan/${planConfigId}`;
      this.$confirm({
        title: "Delete Rep Plan",
        message: `Do you want to delete this plan?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url)
                .then((res) => {
                  let response = res.data.data;
                  this.$notify(response);
                  this.getConfigurations('filterPlanType');
                })
                .catch((err) => {
                  this.$notify(err.response.data.message, "Error", "danger");
                })
                .finally(() => {
                  loader.hide();
                });
          } else {
            // Revert the plan's configured state to its original value
            plan.plan_configured = 1;
          }
        },
      });
    },
    removePlan() {
      const url = `v2/remove-all-selected-plan`;
      this.$confirm({
        title: "Remove Rep Plan",
        message: `Do you want to remove all assigned plan?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            let params = {}
            params['agent_id'] = this.repId
            params['website'] = this.selectedOption
            Api.delete(url, params)
                .then((res) => {
                  let response = res.data;
                  this.$notify(response.message);
                  this.getConfigurations('filterPlanType');
                })
                .catch((err) => {
                  this.$notify(err.response.data.message, 'error');
                })
                .finally(() => {
                  loader.hide();
                });
          }
        },
      });
      

    },
    checkConfiguration(planConfigId, isFeatured) {
      const url = `v2/set-is-featured-plan`;
      let data = {
        plan_config_id: planConfigId,
        is_featured: isFeatured == 1 ? 0 : 1,
      };
      let checkboxElement = event.target;
      let messageText = checkboxElement.checked ? "display" : "hide";

      this.$confirm({
        title: "Feature Plan",
        message: `Do you want to ${messageText} this plan on the homepage?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.put(url, data)
                .then((res) => {
                  let response = res.data.message;
                  this.$notify(response);
                  this.getConfigurations('webSearch');
                })
                .catch((err) => {
                  this.$notify(err.response.data.message, "Error", "danger");
                })
                .finally(() => {
                  loader.hide();
                });
          } else {
            // Revert the checkbox state back to its previous value
            checkboxElement.checked = !checkboxElement.checked;
          }
        },
      });
    },
    closeModal() {
      this.$bvModal.hide("planPrice");
    },
    getConfigurations(status) {
      let loader = this.$loading.show();
      let url = ''
      if (status == 'webSearch' || status == 'clearFilter' ) {
        this.filterPlanType = ''
        this.planCategoryType = ''
        url = `v2/list-active-plans?agent_id=${this.repId}&website=${this.selectedOption}`
      } else if (status == 'filterPlanType') {
        if (this.filterPlanType == 'all') {
          url = `v2/list-active-plans?agent_id=${this.repId}&website=${this.selectedOption}&plan_type=${this.planCategoryType}`
        } else {
          url = `v2/list-active-plans?agent_id=${this.repId}&website=${this.selectedOption}&show_configured=${this.filterPlanType}&plan_type=${this.planCategoryType}`
        }
      } else {
        url = `v2/list-active-plans?agent_id=${this.repId}`
      }
      Api.get(url)
          .then((res) => {
            this.websites = []
            this.activePlans = []
            this.planListDetails = []
            this.websites = res.data.data.website_list;
            this.activePlans = res.data.data.plan_list;
            this.planListDetails = res.data.data.plan_list_details;
            this.searchWebsites = ["All", ...this.websites];
          })
          .catch(() => {
            console.log("Something went wrong!");
          })
          .finally(() => {
            loader.hide();
            if (status == 'webSearch') {
              this.showPlanList = true
            }
          });
    },
    getLabelName(label) {
      return label.charAt(0).toUpperCase() + label.slice(1);
    },
    getPlanCategoryList () {
      let loader = this.$loading.show();
      let url = `${baseApiUrl}api.access/v1/get-plan-category-group`
      axios.get(url)
          .then((res) => {
            this.planCategoryList = res.data.data
            console.log('res', res)
          })
          .catch(() => {
            console.log("Something went wrong!");
          })
          .finally(() => {
            loader.hide();
          });
    },
    removeUnderscores (params) {
      return params.replace(/_/g, ' ');
    }
  },
  created() {
    this.repId = this.$store.getters.repId;
    this.getConfigurations('defaultLoad');
    this.getPlanCategoryList()
  },
};
</script>

<style>
.breadcrumb-item.active{
  background: transparent;
}
.toggle-switch {
  width: 50px;
  height: 30px;
  background-color: #ccc;
  border-radius: 17px;
  position: relative;
  cursor: pointer;

}
.toggle-switch .slider:before{
  background-color: transparent;
  border-radius:50% ;
}

.slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.3s;
}

.active {
  background-color: #1597b8;
}

.active .slider {
  transform: translateX(26px);
}
.toggle-switch_card{
  background-color: rgba(195, 219, 255, 0.337);
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


/* checkbox */

.label-check{
  padding-right: 0.5rem;
  margin-bottom: 0px !important;
  position: relative;
  top: 2px;
}

.plan-wrapper h2 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.drop-down-container {
  width: 100%;
}

.text-capitalize {
  text-transform: capitalize;
}



.clear-search-container .clear-btn {
  position: absolute;
  margin-top: 4px;
  right: 78px;
  background: #03C3EC!important;
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  padding: 8px 12px;
  box-shadow: inherit;
  border: 0;
  text-transform: capitalize;
  font-weight: 500;
}

.remove-container .remove-btn {
  margin-top: 4px;
  background: #03C3EC!important;
  left: 75px;
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  padding: 8px 12px;
  box-shadow: inherit;
  border: 0;
  text-transform: capitalize;
  font-weight: 500; 
}
</style>
