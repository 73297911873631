<template>
  <div class="page-wrapper homepagePlans-wrapper">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title align-items-center plan-page-title">
        <h1 class=""
        >{{ rep.name }} {{ rep.code ? `(${rep.code})` : "" }}</h1>
      </div>
      <homepage-plan-select
          @onSuccessPlanAdd="getPlans"
      />
      <!-- <h2>Configured Plans</h2> -->
      <!-- <div class="planlisting-card">
        <div
          class="d-flex justify-content-between align-items-center top-pagination"
        >
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <multiselect
              v-model="filters.website"
              :options="searchWebsites"
              placeholder="Select Website"
              @input="getPlans"
            />
          </div>
          <page-result :rows.sync="rows" />
        </div>
        <div>
          <input type="checkbox" v-model="allSelected" @change="selectAll" />
          Select All
          <template v-if="selected.length > 0">
            <a
              href="javascript:void(0)"
              @click.prevent="deleteSelected"
              title="Delete Selected"
            >
              <i class="fa fa-trash" />
            </a>
          </template>
        </div>
        <homepage-plan-list
          :rows.sync="rows"
          @onSuccess="getPlans"
          :selected.sync="selected"
          @onSelect="selectHandler"
        />
        <div
          class="d-flex justify-content-between align-items-center top-pagination"
        >
          <page-result :rows.sync="rows" />
          <pagination :rows.sync="rows" :filters="filters" />
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import { mapGetters } from "vuex";
import Helper from "@/resource/Helper";
// import PaginationBar from '../../components/ui/Pagination.vue';
// import HomepagePlanList from "./partials/HomepagePlanList.vue";
import HomepagePlanSelect from "./partials/HomepagePlanSelect.vue";
export default {
  name: "HomepagePlans",
  components: {
    // HomepagePlanList,
    HomepagePlanSelect,
  },
  computed: {
    ...mapGetters({ rep: "rep" }),
  },
  data: () => ({
    rows: [],
    filters: { website: "All" },
    websites: [],
    activePlans: [],
    searchWebsites: [],
    breadcrumbItems: [{ title: "Configured Plans", active: true }],
    selected: [],
    allSelected: false,
    allTotalRow: null,
  }),
  methods: {
    resetSelected() {
      this.selected = [];
      this.allSelected = false;
    },
    deleteSelected() {
      this.$confirm({
        title: "Confirm",
        message: "Are you sure to DELETE these selected plan?",
        button: {
          no: "No",
          yes: "Yes",
        },

        callback: (confirm) => {
          let url = `v3/delete-selected-website-plan`;
          let data = {
            ids: this.selected,
            selected_all: this.allSelected,
          };
          if (confirm) {
            Api.delete(url, data)
                .then((res) => {
                  this.$notify(res.data.message);
                  this.getPlans();
                  this.resetSelected();
                })
                .catch((err) => {
                  console.log("err", err);
                  this.$notify("Something went wrong !", "danger");
                });
          }
        },
      });
    },
    selectHandler(selected) {
      this.selected = selected;
      if (this.allTotalRow ==  this.selected.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
    selectAll() {
      if (this.allSelected) {
        const selected = this.rows.records.map((x) => x.plan_config_id);
        this.selected = selected;
      } else {
        this.allSelected = false;
        this.selected = [];
      }
    },
    getPlans() {
      let url;
      let loader = this.$loading.show();
      let filterUrl = Helper.objToUrlParams(this.filters);
      url = `v2/get-rep-plan-configuration?${filterUrl}`;
      Api.get(url)
          .then((res) => {
            this.rows = res.data;
          })
          .catch(() => {
            this.rows = [];
            console.log("Something went wrong!");
          })
          .finally(() => {
            loader.hide();
          });
    }
  },
  created() {
    this.getPlans();
  },
  watch: {
    "rows.records": function (newVal, oldVal) {
      this.allTotalRow = newVal.length;
      if (newVal != oldVal && this.allSelected) {
        let selected = this.rows.records.map((x) => x.plan_config_id);
        selected = [...this.selected, ...selected];
        /**
         * Sets are a new object type with ES6 (ES2015) that allows you to create collections of unique values.
         */
        this.selected = [...new Set(selected)];
        this.allSelected = true;
      }
    },
  },
};
</script>
<style src="@/assets/css/homepageplans.css"></style>
