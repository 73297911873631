var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper homepagePlans-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "page-title align-items-center plan-page-title"
  }, [_c('h1', {}, [_vm._v(_vm._s(_vm.rep.name) + " " + _vm._s(_vm.rep.code ? "(".concat(_vm.rep.code, ")") : ""))])]), _c('homepage-plan-select', {
    on: {
      "onSuccessPlanAdd": _vm.getPlans
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }